import "@fontsource/noto-sans-tc/400.css"
import "@fontsource/noto-sans-tc/500.css"
import "@fontsource/noto-sans-tc/700.css"
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/500.css";
import "@fontsource/roboto-condensed/700.css";

import 'public/js/sweetalert';
import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;

